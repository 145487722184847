//import './App.css';

//import 'bootstrap/dist/css/bootstrap.css';

import SignUp from "./components/SignUp";
import PartnerSignUp from "./components/PartnerSignUp";
import PriceCalculator from "./components/PriceCalculator";
import SignUpData from "./components/SignUpData";

function App({elementId}) {
    const appName = getAppName(elementId)
    let app = getDefaultApp(elementId)
    switch (appName) {
        case 'sign-up':
            app = <SignUp/>
            break;
        case 'partner-sign-up':
            app = <PartnerSignUp/>
            break;
        case 'price-comparison':
            app = <PriceCalculator/>
            break;
        case 'signup-data':
            app = <SignUpData/>
    }
    return (
        app
    );
}

function getAppName(elementId) {
    if (window.location.hostname === "localhost") {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        const appName = params[elementId]
        return appName
    }
    const el = document.getElementById(elementId);
    const appName = el.getAttribute('data-app')
    return appName
}

function getDefaultApp(elementId) {
    if (window.location.hostname === "localhost") {
        const appLink = "?" + elementId + "="
        return <>
            <a href={appLink + "sign-up"}>Sign up</a>
            <br/><a href={appLink + "partner-sign-up"}>Partner Sign up</a>
            <br/><a href={appLink + "price-comparison"}>Price comparison</a>
            <br/><a href={appLink + "signup-data"}>Signup data</a>
        </>
    }
    return <div>...</div>
}

export default App;
