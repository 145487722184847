import React from 'react';
import Cookies from 'universal-cookie';

export const SIGNUP_DATA_COOKIE = "signUpData"

export default class SignUpData extends React.Component {
    async componentDidMount() {
        const referrer = document.referrer;
        if (referrer === "") {
            await this.setSignIpData(referrer, window.location.href)
        } else {
            const referrerHostname = new URL(referrer).hostname
            if (referrerHostname !== process.env.REACT_APP_WEB_HOSTNAME) {
                await this.setSignIpData(referrer, window.location.href)
            }
        }
    }

    async setSignIpData(source, target) {
        this.cookies = new Cookies()

        let loadedData = this.cookies.get(SIGNUP_DATA_COOKIE)
        if (loadedData == null) {
            loadedData = []
        }

        const record = {
            source: source,
            target: target,
            timeStamp: new Date()
        }
        const newData = loadedData.concat(record)
        this.cookies.set(SIGNUP_DATA_COOKIE, newData, {path: '/', expires: new Date(Date.now() + 2592000)});
    }

    render() {
        return <div/>
    }
}
