import React from 'react';
import countryList from 'react-select-country-list'
import Select from 'react-select'

const countries = countryList().getData();

const regularStyles = {
    control: (provided, state) => ({
        ...provided,
        boxShadow: '0',
        flexWrap: 'nowrap',
        height: '2.5rem',
        borderColor: state.isFocused ? '#003468' : '#ced4da',
        outline: state.isFocused ? 0 : provided.outline,
        backgroundColor: state.isFocused ? '#fff' : provided.backgroundColor,
        color: state.isFocused ? '#495057' : provided.color,
        '&:hover': {
        }
    }),
};

const errorStyles = {
    control: (provided, state) => ({
        ...provided,
        height: '2.5rem',
        borderColor: '#dc3545',
        flexWrap: 'nowrap',
        boxShadow:  state.isFocused ? '0 0 0 .2rem rgba(220, 53, 69,.25)' : 0,
        '&:hover': {
        },

    }),
};

export default class CountrySelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            onChange: props.onChange,
        };

        if (props.selected) {
            this.state.selected = {
                value: props.selected,
                label: countryList().getLabel(props.selected)
            }
        }

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.state.onChange(event.value);
    }

    render() {
        return <Select
            styles={!this.props.hasError ? regularStyles : errorStyles}
            options={countries}
            defaultValue={this.state.selected}
            onChange={this.handleChange}
            hasError={this.props.hasError}
        />
    }
}