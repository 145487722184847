import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


const elements = document.querySelectorAll('[id^="homepage-app"]');

if (elements && elements.length) {
    for (let i = 0; i < elements.length; i++) {
        const elementId = elements[i].id
        ReactDOM.render(
            <React.StrictMode>
                <App elementId={elementId}/>
            </React.StrictMode>,
            document.getElementById(elementId)
        );
    }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
