import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import {BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer} from "recharts"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';

import WpContainer from "./WpContainer";

import {Container} from "react-bootstrap";

const EURtoUSD = 0.85;

let storaderaAmt = 6;
let amazonAmt = 24.5 * EURtoUSD;
let microsoftAmt = 19.6 * EURtoUSD;
let googleAmt = 23 * EURtoUSD;

let storaderaAmtDlded = 0;
let amazonAmtDlded = 90 * EURtoUSD;
let microsoftAmtDlded = 80 * EURtoUSD;
let googleAmtDlded = 120 * EURtoUSD;

const useStyles = theme => ({
    root: {
        minWidth: 180 + theme.spacing(3) * 2,
        maxWidth: 400 + theme.spacing(3) * 2,
    },
    margin: {
        height: theme.spacing(3),
    },
    input: {
        width: 80,
    },
});

function ValueLabelComponent(props) {
    const {children, open, value} = props;

    return (
        <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
            {children}
        </Tooltip>
    );
}

ValueLabelComponent.propTypes = {
    children: PropTypes.element.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.number.isRequired,
};

const PrettoSlider = withStyles({
    root: {
        color: '#115CA6',
        height: 8,
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -8,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
        fontWeight: 400,
    },
    track: {
        height: 8,
        borderRadius: 5,
    },
    rail: {
        height: 8,
        borderRadius: 5,
    },
})(Slider);

const storageAmtMarks = [
    {
        value: 1,
        label: '1 TB',
    },
    {
        value: 1024,
        label: '1 PB',
    },
];

const downloadedAmtMarks = [
    {
        value: 0,
        label: '0%',
    },
    {
        value: 100,
        label: '100%',
    },
];

class PriceCalculator extends React.Component {

    state = {
        amtSlider: 100,
        dldedSlider: 20,
    };

    handleSliderChange = name => (e, value) => {
        this.setState({
            [name]: Number(value)
        });
    }

    handleInputChange(e) {
        let obj = {};
        obj[e.target.name] = Number(e.target.value);
        this.setState(obj);
    }

    render() {

        const {classes} = this.props;

        function currencyAdder(Cost) {
            return Cost.toLocaleString().replace(/,/g, " ") + ' EUR';
        }

        const data = [
            {
                Company: 'Storadera',
                Cost: ((this.state.amtSlider * storaderaAmt) + ((this.state.amtSlider * this.state.dldedSlider)/100) * storaderaAmtDlded)* 12,
                fill: "#115CA6",
                stroke: "#115CA6",
            },
            {
                Company: 'AWS S3',
                Cost: ((this.state.amtSlider * amazonAmt) + ((this.state.amtSlider * this.state.dldedSlider)/100) * amazonAmtDlded)* 12,
                fill: '#FA9C1D',
                stroke: "#FA9C1D"
            },
            {
                Company: 'Microsoft',
                Cost: ((this.state.amtSlider * microsoftAmt) + ((this.state.amtSlider * this.state.dldedSlider)/100) * microsoftAmtDlded)* 12,
                fill: '#36BCF1',
                stroke: "#36BCF1"
            },
            {
                Company: 'Google Cloud',
                Cost: ((this.state.amtSlider * googleAmt) + ((this.state.amtSlider * this.state.dldedSlider)/100) * googleAmtDlded)* 12,
                fill: '#EA4335',
                stroke: "#EA4335"
            },
        ];
        let storaderaYearlyAmt = currencyAdder(data[0].Cost);
        let storaderaMonthlyAmt = currencyAdder((data[0].Cost)/12);

        return <WpContainer>
            <h1 className="price-ctr-h1">Price Comparison</h1>
            <Container className="price-ctr">
                <Row className="space-between">
                    <Col sm={6} className="price-slider" >
                        <Grid container spacing={3} alignItems="center">
                            <Grid item xs className="slider-width">
                                <div className={classes.margin}/>
                                <Typography gutterBottom>Storage Amount</Typography>
                                <PrettoSlider className={classes.root}
                                              valueLabelDisplay="auto"
                                              aria-label="storage amount slider"
                                              defaultValue={100}
                                              min={1}
                                              max={1024}
                                              marks={storageAmtMarks}
                                              value={this.state.amtSlider}
                                              onChange={this.handleSliderChange("amtSlider")}
                                              color="primary"
                                />
                            </Grid>
                            <Grid item>
                                <Input
                                    className={classes.input}
                                    value={this.state.amtSlider}
                                    margin="dense"
                                    name="amtSlider"
                                    onChange={(e) => {
                                        this.handleInputChange(e)
                                    }}
                                    inputProps={{
                                        step: 1,
                                        min: 1,
                                        max: 1024,
                                        type: 'number',
                                        'aria-labelledby': 'input-slider',
                                    }}
                                />
                            </Grid>
                            <Grid><p className="input-unit">TB</p></Grid>
                            <Grid item xs className="slider-width">
                                <div className={classes.margin}/>
                                <Typography gutterBottom>Percentage Downloaded Per Month</Typography>
                                <PrettoSlider className={classes.root}
                                              valueLabelDisplay="auto"
                                              aria-label="percentage downloaded per month slider"
                                              defaultValue={20}
                                              marks={downloadedAmtMarks}
                                              value={this.state.dldedSlider}
                                              onChange={this.handleSliderChange("dldedSlider")}
                                              color="secondary"
                                />
                            </Grid>
                            <Grid item>
                                <Input
                                    className={classes.input}
                                    value={this.state.dldedSlider}
                                    margin="dense"
                                    name="dldedSlider"
                                    onChange={(e) => {
                                        this.handleInputChange(e)
                                    }}
                                    inputProps={{
                                        step: 1,
                                        min: 0,
                                        max: 100,
                                        type: 'number',
                                        'aria-labelledby': 'input-slider',
                                    }}
                                />
                            </Grid>
                            <Grid><p className="input-unit">%</p></Grid>
                        </Grid>
                    </Col>
                    <Col className="calc-width" sm={6}>
                        <div className="price-calc">
                            <Row><h2 className="chart-header">Yearly Cost Graph</h2></Row>
                            <Row>
                            <p className="chart-text-storadera"><b>Storadera:</b></p>
                            <p className="chart-text"><b>{storaderaMonthlyAmt}</b>/month </p>
                            <p className="chart-text"><b>{storaderaYearlyAmt}</b>/year</p>
                        </Row>
                            <ResponsiveContainer aspect={2}>
                                <BarChart
                                    width={700}
                                    data={data}
                                    margin={{
                                        top: 30,
                                        right: 30,
                                        left: 10,
                                        bottom: 0
                                    }}
                                    barCategoryGap={25}
                                    barGap={25}
                                >
                                    <XAxis dataKey="Company" scale="point" padding={{left: 40, right: 40}}
                                           dy={5}
                                           tickLine={true}
                                           fontSize={12}
                                           tick={{fill: "#003468"}}
                                           minTickGap={5}
                                    />
                                    <YAxis
                                        dx={-5}
                                        tickFormatter={currencyAdder}
                                        fontSize={12}
                                        tick={{fill: "#003468"}}
                                        width={110}
                                    />
                                    <Tooltip
                                        wrapperStyle={{backgroundColor: "red"}}
                                        labelStyle={{color: "#115CA6"}}
                                        itemStyle={{color: "#003468"}}
                                        formatter={currencyAdder}
                                    />
                                    <CartesianGrid strokeDasharray="10 10"/>
                                    <Bar dataKey="Cost" fill="#003468" strokeWidth={2}
                                    />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </Col>
                </Row>
            </Container>
        </WpContainer>

    }
}

export default withStyles(useStyles)(PriceCalculator);


