import axios from "axios";

const axiosConfig = {withCredentials: true};

export async function get(path) {
    try {
        const res = await axios.get(getHost() + path, axiosConfig);
        return {res: res, err: null};
    } catch (e) {
        return {res: null, err: e};
    }
}

export async function post(path, data) {
    try {
        const res = await axios.post(getHost() + path, data, axiosConfig);
        return {res: res, err: null};
    } catch (e) {
        return {res: null, err: e};
    }
}

export function getHost() {
    return process.env.REACT_APP_BACKEND;
}