import React from "react"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Form from "react-bootstrap/Form"
import Row from "react-bootstrap/Row"
import {Alert} from "react-bootstrap"
import ReCAPTCHA from "react-google-recaptcha";
import {post} from "../utils/request"
import CountrySelector from "./CountrySelector"

import WpContainer from "./WpContainer";


class PartnerSignUp extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {
                address: "",
                email: "",
                partnerName: "",
                countryCode: "",
                message: "",
                partnerUserName: "",
                pass: "",
                registrationNumber: "",
                recaptcha: "",
                source: [],
                vatNumber: "",
            },
            errorMessage: "",
            errors: {},
            specialDesign: props.specialDesign,
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleCountryCodeChange = this.handleCountryCodeChange.bind(this)
        this.handleRecaptchaChange = this.handleRecaptchaChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.hasError = this.hasError.bind(this)
        this.showError = this.showError.bind(this)
    }

    handleChange(event) {
        const target = event.target
        const value = target.type === "checkbox" ? target.checked : target.value
        const name = target.name

        const data = this.state.data
        data[name] = value
        this.setState({date: data})
    }

    handleCountryCodeChange(newValue) {
        const data = this.state.data
        data.countryCode = newValue
        this.setState({data: data})
    }

    handleRecaptchaChange(value) {
        const data = this.state.data
        data.recaptcha = value
        this.setState({data: data})
    }

    async handleSubmit(event) {
        event.preventDefault()

        this.setState({errorMessage: ""})

        const data = this.state.data
        if (`${process.env.NODE_ENV}` === "development") {
            data.recaptcha = "testVerifyOk"
        }

        const {res, err} = await post("/partner/signUp", data);
        this.setState({errors: {}})
        this.setState({errorMessage: ""})
        if (err) {
            if (err.response && err.response.status) {
                // in backend, we do not check recaptcha if there is a validation error. And thus, we do not use the code.
                if (err.response.status != 422) {
                    window.grecaptcha.reset();
                }
                switch (err.response.status) {
                    case 409:
                        this.setState({errorMessage: "This email already exists"})
                        return
                    case 410:
                        this.setState({errors: {Recaptcha: 1}})
                        return
                    case 422:
                        const errors = err.response.data.ValidationErrors
                        this.setState({errors: errors})
                        return
                    default:
                        return
                }
            }
            this.setState({errorMessage: "Error: " + err})
            return
        }

        window.location.href = "/partner-sign-up-done"
    }

    hasError(field) {
        if (this.state.errors[field]) {
            return true
        }
        return false
    }

    showError(field) {
        const error = this.state.errors[field]

        if (error) {
            this.errorTranslator(error, field)
            return error.message;
        }
        return
    }

    errorTranslator(error, field) {
        if (error.key === "length") {
            switch (field) {
                case "partnerUserName":
                    error.message = "Please enter a name";
                    break;
                case "pass":
                    error.message = "Please enter a " + field.toLowerCase() + "word at least 10 characters long";
                    break;
                case "address":
                    error.message = "Please enter an " + field.toLowerCase() + " at least 6 characters long";
                    break;
                case "partnerName":
                    error.message = "Please enter a company name";
                    break;
                case "registrationNumber":
                    error.message = "Please enter a " + field.toLowerCase() + " at least 4 characters long";
                    break;
                case "vatNumber":
                    error.message = "Please enter a " + field.toLowerCase() + " at least 4 characters long";
                    break;
             }

        } else if (error.key === "email") {
            error.message = "Please enter your " + field.toLowerCase() + " in format: yourname@example.com";
        }
    }

    render() {
        let classes = {};
        classes.formLabel = "col-4 col-form-label text-nowrap";

        return <WpContainer>
            <Container>
                {this.state.errorMessage && <Alert variant="danger">{this.state.errorMessage}</Alert>}
                <Form onSubmit={e => this.handleSubmit(e)} className="signup-styles">
                    <h1>Sign up</h1>
                    <Form.Group as={Row} controlId="formPlaintextEmail">
                        <Form.Label className={classes.formLabel}>Email</Form.Label>
                        <Col>
                            <Form.Control
                                type="email"
                                name="email"
                                isInvalid={this.hasError("email")}
                                onChange={this.handleChange}
                                className="form-control input-custom-fix"
                            />
                            <Form.Control.Feedback
                                type="invalid"
                                className="invalid-feedback">{this.showError("email")}</Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formPlaintextPartnerUserName">
                        <Form.Label className={classes.formLabel}>Name</Form.Label>
                        <Col>
                            <Form.Control
                                type="text"
                                name="partnerUserName"
                                isInvalid={this.hasError("partnerUserName")}
                                onChange={this.handleChange}
                                className="form-control input-custom-fix"
                            />
                            <Form.Control.Feedback
                                type="invalid"
                                className="invalid-feedback">{this.showError("partnerUserName")}</Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formPassword">
                        <Form.Label className={classes.formLabel}>Password</Form.Label>
                        <Col>
                            <Form.Control
                                type="password"
                                name="pass"
                                isInvalid={this.hasError("pass")}
                                onChange={this.handleChange}
                                className="form-control input-custom-fix"
                            />
                            <Form.Control.Feedback
                                type="invalid"
                                className="invalid-feedback">{this.showError("pass")}</Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formCountryCode" className="additional-padding">
                        <Form.Label className={classes.formLabel}>Country</Form.Label>
                        <Col>
                            <CountrySelector
                                onChange={this.handleCountryCodeChange.bind(this)}
                                hasError={this.hasError("countryCode")}
                                className="form-control"
                            />
                            {this.hasError("countryCode") &&
                                <div className="invalid-feedback">Please select a country</div>
                            }
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formPartnerName">
                        <Form.Label className={classes.formLabel}>Company Name</Form.Label>
                        <Col>
                            <Form.Control
                                type="text"
                                name="partnerName"
                                isInvalid={this.hasError("partnerName")}
                                onChange={this.handleChange}
                                className="form-control input-custom-fix"
                            />
                            <Form.Control.Feedback
                                className="invalid-feedback"
                                type="invalid">{this.showError("partnerName")}</Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formAddress">
                        <Form.Label className={classes.formLabel}>Address</Form.Label>
                        <Col>
                            <Form.Control
                                type="text"
                                name="address"
                                isInvalid={this.hasError("address")}
                                onChange={this.handleChange}
                                className="form-control input-custom-fix"
                            />
                            <Form.Control.Feedback
                                className="invalid-feedback"
                                type="invalid">{this.showError("address")}</Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formRegistrationNumber">
                        <Form.Label className={classes.formLabel}>Registration Number</Form.Label>
                        <Col>
                            <Form.Control
                                type="text"
                                name="registrationNumber"
                                isInvalid={this.hasError("registrationNumber")}
                                onChange={this.handleChange}
                                className="form-control input-custom-fix"
                            />
                            <Form.Control.Feedback
                                className="invalid-feedback"
                                type="invalid">{this.showError("registrationNumber")}</Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formVatNumber">
                        <Form.Label className={classes.formLabel}>Vat Number</Form.Label>
                        <Col>
                            <Form.Control
                                type="text"
                                name="vatNumber"
                                isInvalid={this.hasError("vatNumber")}
                                onChange={this.handleChange}
                                className="form-control input-custom-fix"
                            />
                            <Form.Control.Feedback
                                className="invalid-feedback"
                                type="invalid">{this.showError("vatNumber")}</Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formPlaintextMessage">
                        <Form.Label column sm="12">
                            Please provide any additional info necessary.
                            (optional)</Form.Label>
                        <Col sm="12">
                            <Form.Control
                                as="textarea"
                                type="text"
                                name="message"
                                rows="3"
                                isInvalid={this.hasError("message")}
                                onChange={this.handleChange}
                                className="form-control text-area-sizing"
                            />
                            <Form.Control.Feedback className="invalid-feedback"
                                                   type="invalid">{this.showError("message")}</Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <ReCAPTCHA
                        sitekey="6LeM3ggbAAAAABKhMTQC1xeGBR4mxHGi7KLx6wH3"
                        onChange={this.handleRecaptchaChange}
                    />
                    {this.hasError("Recaptcha") &&
                        <div className="invalid-feedback">
                            Please verify you are a real person
                            <br/>&nbsp;
                        </div>
                    }
                    <Button type="submit"><b>SIGN UP</b></Button>
                    <div className="signup-terms">
                        <p>By signing up you agree to our&nbsp;
                            <a href="/terms" target="_blank">Terms and Conditions</a>
                            &nbsp;and <a href="/privacy" target="_blank">Privacy
                                Policy</a></p>
                    </div>
                </Form>
                <Col md={{order: "last"}} lg={{order: "last"}} style={{
                    marginTop: "1rem"
                }}>
                </Col>
            </Container>
        </WpContainer>
    }
}

export default PartnerSignUp